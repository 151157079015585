















import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ExtendedRunConcentration } from '@/store/interface/Concentration';
import { RunTask } from '@/store/interface/Task';

const CommonIcon = () => import('common-modules/src/components/CommonIcon.vue');

@Component({
  components: {
    CommonIcon,
  },
  computed: mapGetters([
    'concentration',
  ]),
})
export default class JwlCourseTasks extends Vue {
  concentration!: ExtendedRunConcentration;

  @Prop(Array)
  runTasks!: RunTask[];

  taskState (task: RunTask): string {
    if (this.concentration.role !== 'student') {
      return '';
    }

    const { submitted } = task;

    if (submitted && submitted.hasSubmitted) {
      if (submitted.hasPublished) {
        if (submitted.grade && submitted.grade.date) {
          if (task.maxScore > 0 && submitted.grade.score === 0) {
            return '0-grade';
          }
          return 'grade';
        }
        return 'ungraded';
      }
      return 'unpublished';
    }

    const now = new Date();

    if (task.studentDeadline) {
      const deadline = new Date(task.studentDeadline);

      if (deadline < now && task.maxScore > 0) {
        return 'deadline-passed';
      }
    }

    if (task.startDate) {
      const startDate = new Date(task.startDate);

      return startDate <= now && task.maxScore > 0
        ? 'nothing'
        : '';
    }

    return '';
  }

  taskStateClass (task: RunTask): Record<string, boolean> {
    const taskState = this.taskState(task);
    return {
      'jwl-course-tasks__group-item--student': true,
      'jwl-course-tasks__group-item--0-grade': taskState === '0-grade',
      'jwl-course-tasks__group-item--grade': taskState === 'grade',
      'jwl-course-tasks__group-item--ungraded': taskState === 'ungraded',
      'jwl-course-tasks__group-item--unpublished': taskState === 'unpublished',
      'jwl-course-tasks__group-item--deadline-passed': taskState === 'deadline-passed',
      'jwl-course-tasks__group-item--nothing': taskState === 'nothing',
      'jwl-course-tasks__group-item--else': taskState === '',
    };
  }

  taskIconState (task: RunTask): string {
    switch (this.taskState(task)) {
      case '0-grade':
      case 'grade':
      case 'ungraded':
        return 'check-circle';
      case 'unpublished':
      case 'nothing':
        return 'clock';
      case 'deadline-passed':
        return 'exclamation-circle';
      default:
        return '';
    }
  }

  get groupedTasks (): Record<string, RunTask[]> {
    const groups: Record<string, RunTask[]> = {
      attendance: [],
      discuss: [],
      submit_text: [],
      submit_work: [],
      track: [],
      quiz: [],
    };

    this.runTasks.forEach((task) => {
      const taskGroup = groups[task.type];
      if (taskGroup) {
        taskGroup.push(task);
      }
    });

    return groups;
  }
}
